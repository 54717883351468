<template>
  <div class="animated fadeIn">
    <table-custom
      ref="dataTable"
      :name="`${$customTable.getCustomTableName($route.meta.module)}`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
    >
      <div slot="afterFilter">
        <b-row>
          <b-col>
            <b-button-group>
              <b-button
                variant="outline-dark"
                title="Create"
                @click="addItem()"
              >
                <font-awesome-icon icon="plus" /> Create
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </div>
      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <button
            class="btn btn-dark btn-sm"
            @click="viewContent(props.row)"
            title="Preview"
          >
            <font-awesome-icon icon="search" />
          </button>

          <button
            class="btn btn-primary btn-sm"
            @click="viewItem(props.row.ID)"
          >
            <font-awesome-icon icon="eye" />
          </button>
          <button
            class="btn btn-success btn-sm"
            @click="editItem(props.row.ID)"
          >
            <font-awesome-icon icon="pencil-alt" />
          </button>
          <button
            class="btn btn-danger btn-sm"
            @click="deleteItem(props.row.ID)"
          >
            <font-awesome-icon icon="trash" />
          </button>
          <button
            :class="[
              props.row['FilesCount'] !== '0'
                ? 'btn btn-secondary btn-sm'
                : 'btn btn-outline-secondary btn-sm'
            ]"
            @click="toggleFiles(props.row['ID'])"
          >
            <font-awesome-icon icon="download" />
          </button>
        </div>
      </div>
      <span slot-scope="props" slot="child_row">
        <files-container
          ref="files"
          :module-id="$route.meta.module.id"
          :entity-id="props.row.ID"
        />
      </span>
    </table-custom>

    <b-modal
      ref="preview-modal"
      centered
      hide-footer
      size="lg"
      :title="previewModal.title"
    >
      <b-card>
        <b-card-body>
          <b-row>
            <b-col lg="6">
              <strong>Support name: </strong> {{ previewModal.supportName }}
            </b-col>
            <b-col lg="6">
              <strong>Account: </strong> {{ previewModal.accountName }}
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6">
              <strong>Goal: </strong> {{ previewModal.goal }}
            </b-col>
            <b-col lg="6">
              <strong>Type: </strong> {{ previewModal.type }}
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6">
              <strong>Date start: </strong> {{ previewModal.dateStart }}
            </b-col>
            <b-col lg="6">
              <strong>Date end: </strong> {{ previewModal.dateEnd }}
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6">
              <strong>Cost estimate: </strong> {{ previewModal.costEstimate }}
            </b-col>
            <b-col lg="6">
              <strong>Cost actual: </strong> {{ previewModal.costActual }}
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6">
              <strong>Date closed: </strong> {{ previewModal.dateClosed }}
            </b-col>

            <b-col lg="6">
              <strong>Closure notes: </strong> {{ previewModal.closureNotes }}
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6">
              <strong>Date paid: </strong> {{ previewModal.datePaid }}
            </b-col>

            <b-col lg="6">
              <strong>Partner contribution: </strong>
              {{ previewModal.partnerContribution }}
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12">
              <strong>Description: </strong> {{ previewModal.description }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <strong>Files: </strong>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-for="(f, index) in previewModal.files"
              :key="`rec-${index}`"
            >
              <img
                v-if="f.type === 'image'"
                v-auth-image="f.url"
                class="receipt-thumbnail"
              />
              <div v-if="f.type !== 'image'">
                {{ f.name }}
                <b-button
                  class="m-1"
                  variant="success"
                  size="sm"
                  @click="downloadItem(f)"
                >
                  <font-awesome-icon icon="download" />
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <hr />
      <div class="form-row d-flex  justify-content-end">
        <b-button
          v-if="!previewModal.approved && previewModal.isReviewer"
          variant="warning"
          class="m-1"
          @click="setIncentiveApproved()"
        >
          <b-spinner v-if="previewModal.isLoadingApprove" small type="grow" />
          <font-awesome-icon
            v-if="!previewModal.isLoadingApprove"
            icon="signature"
          />
          Mark as Approved
        </b-button>
        <b-button
          variant="outline-dark"
          class="m-1"
          @click="closePreviewModal()"
        >
          <font-awesome-icon icon="times" /> Close
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";

import TableCustom from "@/components/TableCustom";

import FilesContainer from "@/components/FilesContainer";

export default {
  props: [],
  name: "IncentivesTable",
  components: {
    TableCustom,
    FilesContainer
  },
  data: function() {
    return {
      rawData: [],
      dataTable: {
        isLoading: false,
        dataSet: [],
        options: {
          columns: [
            "ID",
            "Created by",
            "Support name",
            "Type",
            "Date start",
            "Date end",
            "Goal",
            "Description",
            "Market",
            "Country",
            "State",
            "City",
            "Account name",
            "Category",
            "Cost estimate",
            "Cost actual",
            "Date paid",
            "Approved",
            "Approved by",
            "Date closed",
            "Closure notes",
            "Partner contribution"
          ],
          filterable: [
            "ID",
            "Created by",
            "Support name",
            "Type",
            "Date start",
            "Date end",
            "Goal",
            "Description",
            "Market",
            "Country",
            "State",
            "City",
            "Account name",
            "Category",
            "Cost estimate",
            "Cost actual",
            "Date paid",
            "Approved",
            "Approved by",
            "Date closed",
            "Closure notes",
            "Partner contribution"
          ],
          formatColumns: [
            {
              name: "Cost estimate",
              style: {
                style: "currency",
                currency: "USD"
              }
            },
            {
              name: "Cost actual",
              style: {
                style: "currency",
                currency: "USD"
              }
            }
          ],
          footerHeadings: false,
          perPage: 50,
          showActions: true,
          showCustomActions: true,
          showChildRows: true
        }
      },
      previewModal: {
        id: "",
        title: "",
        supportName: "",
        accountName: "",
        goal: "",
        type: "",
        dateStart: "",
        dateEnd: "",
        description: "",
        datePaid: "",
        dateClosed: "",
        closureNotes: "",
        partnerContribution: "",
        isReviewer: false,
        approved: false,
        isLoadingApprove: false,
        files: []
      }
    };
  },
  computed: {},
  created() {},
  methods: {
    toggleFiles(id) {
      this.$refs.dataTable.toggleChildRow(id);
    },
    setIncentiveApproved() {
      let self = this;
      let id = this.previewModal.id;

      self.previewModal.isLoadingApprove = true;

      self.$api
        .put(`incentives/${id}`, {
          id: id,
          approved: true
        })
        .then(response => {
          self.previewModal.isLoadingApprove = false;
          self.previewModal.approved = true;

          self.getData({
            silentUpdate: true
          });

          self.$form.makeToastInfo(response.message);
        })
        .catch(error => {
          console.log(error);

          self.$form.makeToastError(error.message);
        });
    },
    closePreviewModal: function() {
      this.$refs["preview-modal"].hide();
    },
    viewContent(item) {
      this.previewModal.id = item["ID"];
      this.previewModal.title = `Support #${item["ID"]}`;
      this.previewModal.supportName = item["Support name"];
      this.previewModal.accountName = item["Account name"];
      this.previewModal.goal = item["Goal"];
      this.previewModal.type = item["Type"];
      this.previewModal.dateStart = item["Date start"];
      this.previewModal.dateEnd = item["Date end"];
      this.previewModal.description = item["Description"];
      this.previewModal.datePaid = item["Date paid"];
      this.previewModal.dateClosed = item["Date closed"];
      this.previewModal.closureNotes = item["Closure notes"];
      this.previewModal.partnerContribution = item["Partner contribution"];

      this.previewModal.approved = item["Approved"] === "Yes";
      this.previewModal.isReviewer = item["is_reviewer"] == "1";

      this.previewModal.files = [];
      let files = item["file_ids"] ? item["file_ids"].split("|") : [];

      files.forEach(f => {
        let a = f.split(";");
        let file = {};
        file.url = process.env.VUE_APP_API_URL + "/files/" + a[0];
        file.name = a[1];
        file.type = a[2];
        this.previewModal.files.push(file);
      });

      this.$refs["preview-modal"].show();
    },
    downloadItem: function(file) {
      let self = this;

      axios({
        url: file.url,
        method: "GET",
        responseType: "blob" // important
      })
        .then(response => {
          const blob = new Blob([response.data], {
            type: response.data.type
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          //const contentDisposition = response.headers['content-disposition'];
          let fileName = file.name;

          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch(response => {
          console.log(response);

          self.$form.makeToastError(response.message);
        });
    },

    onFilter() {
      this.dataTable.totalRecords = this.$refs.dataTable.data.length;
    },
    getData: async function(payload) {
      let self = this;

      //display spinner  by default
      if (!payload || !payload.silentUpdate) {
        self.dataTable.isLoading = true;
        self.dataTable.dataSet = [];
      }

      let response = [];

      try {
        response = await this.$api.post("incentives", payload);

        self.rawData = response;

        self.dataTable.isLoading = false;

        if (response.length === 0) return;

        self.dataTable.dataSet = response;

        self.$emit("loaded", self.dataTable.dataSet.length);
      } catch (error) {
        console.log(error);

        self.dataTable.isLoading = false;

        self.$form.msgBoxOk("Error occured");
      }
    },
    addItem: function() {
      this.$router.push({
        name: "Incentive submission",
        params: {
          action: "create"
        }
      });
    },
    viewItem: function(id) {
      this.$router.push({
        name: "Incentive submission",
        params: {
          action: "view",
          id: id
        }
      });
    },
    editItem: function(id) {
      this.$router.push({
        name: "Incentive submission",
        params: {
          action: "edit",
          id: id
        }
      });
    },
    deleteItem: async function(id) {
      let item = this.dataTable.dataSet.find(item => item.ID === id);

      let confirm = await this.$form.showConfirmation(
        `Incentive #${item.ID} will be deleted. Do you want to proceed?`
      );

      if (!confirm) return;

      let self = this;

      this.$api
        .delete(`incentives/${item.ID}`)
        .then(response => {
          self.$form.makeToastInfo(response.message);

          self.getData({
            silentUpdate: true
          });
        })
        .catch(response => {
          console.log(response);

          self.$form.makeToastError(response.message);
        });
    }
  },
  watch: {}
};
</script>

<style scoped></style>
