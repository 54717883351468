var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('table-custom', {
    ref: "dataTable",
    attrs: {
      "name": "".concat(_vm.$customTable.getCustomTableName(_vm.$route.meta.module)),
      "loading": _vm.dataTable.isLoading,
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options
    },
    scopedSlots: _vm._u([{
      key: "custom-actions",
      fn: function fn(props) {
        return _c('div', {}, [_c('div', {
          staticClass: "btn-group"
        }, [_c('button', {
          staticClass: "btn btn-dark btn-sm",
          attrs: {
            "title": "Preview"
          },
          on: {
            "click": function click($event) {
              return _vm.viewContent(props.row);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "search"
          }
        })], 1), _c('button', {
          staticClass: "btn btn-primary btn-sm",
          on: {
            "click": function click($event) {
              return _vm.viewItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "eye"
          }
        })], 1), _c('button', {
          staticClass: "btn btn-success btn-sm",
          on: {
            "click": function click($event) {
              return _vm.editItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "pencil-alt"
          }
        })], 1), _c('button', {
          staticClass: "btn btn-danger btn-sm",
          on: {
            "click": function click($event) {
              return _vm.deleteItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "trash"
          }
        })], 1), _c('button', {
          class: [props.row['FilesCount'] !== '0' ? 'btn btn-secondary btn-sm' : 'btn btn-outline-secondary btn-sm'],
          on: {
            "click": function click($event) {
              return _vm.toggleFiles(props.row['ID']);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "download"
          }
        })], 1)])]);
      }
    }, {
      key: "child_row",
      fn: function fn(props) {
        return _c('span', {}, [_c('files-container', {
          ref: "files",
          attrs: {
            "module-id": _vm.$route.meta.module.id,
            "entity-id": props.row.ID
          }
        })], 1);
      }
    }])
  }, [_c('div', {
    attrs: {
      "slot": "afterFilter"
    },
    slot: "afterFilter"
  }, [_c('b-row', [_c('b-col', [_c('b-button-group', [_c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "title": "Create"
    },
    on: {
      "click": function click($event) {
        return _vm.addItem();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "plus"
    }
  }), _vm._v(" Create ")], 1)], 1)], 1)], 1)], 1)]), _c('b-modal', {
    ref: "preview-modal",
    attrs: {
      "centered": "",
      "hide-footer": "",
      "size": "lg",
      "title": _vm.previewModal.title
    }
  }, [_c('b-card', [_c('b-card-body', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('strong', [_vm._v("Support name: ")]), _vm._v(" " + _vm._s(_vm.previewModal.supportName) + " ")]), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('strong', [_vm._v("Account: ")]), _vm._v(" " + _vm._s(_vm.previewModal.accountName) + " ")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('strong', [_vm._v("Goal: ")]), _vm._v(" " + _vm._s(_vm.previewModal.goal) + " ")]), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('strong', [_vm._v("Type: ")]), _vm._v(" " + _vm._s(_vm.previewModal.type) + " ")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('strong', [_vm._v("Date start: ")]), _vm._v(" " + _vm._s(_vm.previewModal.dateStart) + " ")]), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('strong', [_vm._v("Date end: ")]), _vm._v(" " + _vm._s(_vm.previewModal.dateEnd) + " ")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('strong', [_vm._v("Cost estimate: ")]), _vm._v(" " + _vm._s(_vm.previewModal.costEstimate) + " ")]), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('strong', [_vm._v("Cost actual: ")]), _vm._v(" " + _vm._s(_vm.previewModal.costActual) + " ")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('strong', [_vm._v("Date closed: ")]), _vm._v(" " + _vm._s(_vm.previewModal.dateClosed) + " ")]), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('strong', [_vm._v("Closure notes: ")]), _vm._v(" " + _vm._s(_vm.previewModal.closureNotes) + " ")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('strong', [_vm._v("Date paid: ")]), _vm._v(" " + _vm._s(_vm.previewModal.datePaid) + " ")]), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('strong', [_vm._v("Partner contribution: ")]), _vm._v(" " + _vm._s(_vm.previewModal.partnerContribution) + " ")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('strong', [_vm._v("Description: ")]), _vm._v(" " + _vm._s(_vm.previewModal.description) + " ")])], 1), _c('b-row', [_c('b-col', [_c('strong', [_vm._v("Files: ")])])], 1), _c('b-row', _vm._l(_vm.previewModal.files, function (f, index) {
    return _c('b-col', {
      key: "rec-".concat(index)
    }, [f.type === 'image' ? _c('img', {
      directives: [{
        name: "auth-image",
        rawName: "v-auth-image",
        value: f.url,
        expression: "f.url"
      }],
      staticClass: "receipt-thumbnail"
    }) : _vm._e(), f.type !== 'image' ? _c('div', [_vm._v(" " + _vm._s(f.name) + " "), _c('b-button', {
      staticClass: "m-1",
      attrs: {
        "variant": "success",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          return _vm.downloadItem(f);
        }
      }
    }, [_c('font-awesome-icon', {
      attrs: {
        "icon": "download"
      }
    })], 1)], 1) : _vm._e()]);
  }), 1)], 1)], 1), _c('hr'), _c('div', {
    staticClass: "form-row d-flex justify-content-end"
  }, [!_vm.previewModal.approved && _vm.previewModal.isReviewer ? _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "warning"
    },
    on: {
      "click": function click($event) {
        return _vm.setIncentiveApproved();
      }
    }
  }, [_vm.previewModal.isLoadingApprove ? _c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e(), !_vm.previewModal.isLoadingApprove ? _c('font-awesome-icon', {
    attrs: {
      "icon": "signature"
    }
  }) : _vm._e(), _vm._v(" Mark as Approved ")], 1) : _vm._e(), _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.closePreviewModal();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "times"
    }
  }), _vm._v(" Close ")], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }