<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="pb-3">
        <b-row class="pt-3">
          <b-col>
            <filtering-panel
              ref="filteringPanel"
              mode="client"
              dataset-name="incentives"
              :load-dictionaries="loadDictionaries"
              :loaded="filteringPanel.loaded"
              :show-labels="false"
              :filters="filteringPanel.filters"
              @dict-loaded="onFilteringPanelDictionariesLoad"
              @search="getData"
              @change="filterData"
              @loaded="onFilteringPanelLoad"
              @custom-filter-selected="onCustomFilterSelected"
              @state-changed="onFilteringPanelStateChange"
            />
          </b-col>
        </b-row>
        <b-row v-show="dataTable.visible">
          <b-col>
            <incentives-table ref="incentives" @loaded="onIncentivesLoad" />
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import FilteringPanel from "@/components/FilteringPanel";

import IncentivesTable from "@/views/Incentives/IncentivesTable";

export default {
  name: "Incentives",
  components: {
    FilteringPanel,
    IncentivesTable
  },
  data: function() {
    return {
      filteringPanel: {
        selected: {},
        loaded: false,
        filters: [
          {
            type: "select",
            dataType: "territory", //required!! because of virtual entity nature
            title: "Territory",
            tooltip: "Distributor billing territory",
            name: "territory",
            trackby: "id",
            label: "label",
            multiple: false,
            options: [],
            selected: {}
          },
          {
            type: "select",
            dataType: "string",
            title: "Market",
            tooltip: "Sales market",
            name: "market",
            trackby: "id",
            label: "label",
            multiple: true,
            options: [],
            selected: {}
          },
          {
            type: "select",
            dataType: "string",
            title: "Distributors",
            tooltip: "Distributors",
            name: "distributor",
            trackby: "id",
            label: "label",
            multiple: true,
            options: [],
            selected: {}
          },
          {
            type: "select",
            dataType: "string",
            title: "Category",
            tooltip: "Account categories",
            name: "category",
            trackby: "id",
            label: "label",
            multiple: true,
            options: [],
            selected: {}
          },
          {
            type: "select",
            dataType: "string",
            title: "Type",
            tooltip: "Support types",
            name: "type",
            trackby: "id",
            label: "label",
            multiple: true,
            options: [],
            selected: {}
          }
        ]
      },
      dataTable: {
        visible: true
      }
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    onFilteringPanelStateChange() {
      this.dataTable.visible = !this.$refs.filteringPanel.expressionBuilder
        .visible;
    },
    onFilteringPanelDictionariesLoad() {
      this.getData();
    },
    onFilteringPanelLoad() {},
    onCustomFilterSelected(e) {
      if (e.data) this.onSelectedExpression(e);
    },
    //apply filters to loaded data
    onIncentivesLoad() {
      //if isCustomFiltersBar is active then apply custom filters
      if (this.$refs.filteringPanel.panelState.isCustomFiltersBar) {
        let e = this.$refs["filteringPanel"].selected["custom-filter"];

        if (e && e.data) this.onSelectedExpression(e);
      }
      //otherwise apply simple filters
      else {
        //apply selected filters
        this.filterData(this.$refs.filteringPanel.selected);
      }
    },
    onSelectedExpression(e) {
      //if selected expression is empty then apply usual filters
      if (!e || !e.data) {
        //apply selected filters
        this.filterData(this.$refs.filteringPanel.selected);
        return;
      }

      let rawData = this.$refs["incentives"].rawData;

      let expressions = JSON.parse(e.data);

      let territories = this.filteringPanel.filters.find(
        f => f.name === "territory"
      ).options;

      let filteredData = this.$helpers.applyExpressions2Dataset(
        expressions,
        rawData,
        territories
      );

      this.$refs["incentives"].dataTable.dataSet = filteredData;

      this.presetFilteredData = filteredData;
    },
    filterData: function(e) {
      //clone selected filters received from filtering panel via refs
      this.filteringPanel.selected = e;

      if (!this.$refs["incentives"].rawData.length) return;

      let filteredData = this.$refs["incentives"].rawData;

      //if preset is selected then use filtered dataset
      if (
        this.$refs.filteringPanel.panelState.isCustomFiltersBar &&
        this.presetFilteredData &&
        this.$refs["filteringPanel"].selected["custom-filter"]
      )
        filteredData = this.presetFilteredData;

      if (this.filteringPanel.selected !== undefined) {
        filteredData = filteredData.filter(i =>
          this.filteringPanel.selected.market &&
          this.filteringPanel.selected.market.length > 0
            ? this.filteringPanel.selected.market.find(
                s => s.label === i["Market"]
              )
            : true
        );

        filteredData = filteredData.filter(i =>
          this.filteringPanel.selected.distributor &&
          this.filteringPanel.selected.distributor.length > 0
            ? this.filteringPanel.selected.distributor.find(
                s => s.label === i["Account name"]
              )
            : true
        );

        filteredData = filteredData.filter(i =>
          this.filteringPanel.selected.category &&
          this.filteringPanel.selected.category.length > 0
            ? this.filteringPanel.selected.category.find(
                s => s.label === i["Category"]
              )
            : true
        );

        filteredData = filteredData.filter(i =>
          this.filteringPanel.selected.type &&
          this.filteringPanel.selected.type.length > 0
            ? this.filteringPanel.selected.type.find(s => s.label === i["Type"])
            : true
        );

        /******************** Filter by territory ******************************************/
        if (this.filteringPanel.selected.territory) {
          const t = JSON.parse(
            this.filteringPanel.selected.territory.restrictions
          );

          let territory = {
            countries: [
              ...new Set(t.filter(i => i.country !== "").map(i => i.country))
            ],
            states: [
              ...new Set(t.filter(i => i.state !== "").map(i => i.state))
            ],
            cities: [...new Set(t.filter(i => i.city !== "").map(i => i.city))]
          };

          filteredData = filteredData.filter(i => {
            if (
              territory.countries.includes(i["Country"]) &&
              (territory.states.length
                ? territory.states.includes(i["State"])
                : true) &&
              (territory.cities.length
                ? territory.cities.includes(i["City"])
                : true)
            ) {
              return true;
            }
          });
        }
        /*********************************************************************************/
      }

      this.$refs["incentives"].dataTable.dataSet = filteredData;
    },
    async getData() {
      await this.$refs["incentives"].getData();
    },
    async loadDictionaries() {
      let self = this;

      const territories = async () => {
        let response = await self.$api.get("dictionaries/territories");

        let territories = response.map(u => ({
          id: u.ID,
          label: u.Name,
          restrictions: u.restrictions
        }));

        self.filteringPanel.filters.find(
          f => f.name === "territory"
        ).options = territories;
      };

      const distributors = async () => {
        let response = await self.$api.get(
          "dictionaries/accounts/distributors"
        );

        let distributors = response.map(u => ({
          id: u.id,
          label: u.name
        }));
        self.filteringPanel.filters.find(
          f => f.name === "distributor"
        ).options = distributors;
      };

      const markets = async () => {
        let response = await self.$api.get("dictionaries/markets");

        let markets = response.map(u => ({
          id: u.id,
          label: u.name
        }));
        self.filteringPanel.filters.find(
          f => f.name === "market"
        ).options = markets;
      };
      const types = async () => {
        let response = await self.$api.get("dictionaries/incentives/types");

        let types = response.map(u => ({
          id: u.id,
          label: u.name
        }));
        self.filteringPanel.filters.find(
          f => f.name === "type"
        ).options = types;
      };
      const categories = async () => {
        let response = await self.$api.get("dictionaries/accounts/categories");

        let categories = response.map(u => ({
          id: u.id,
          label: u.name
        }));
        self.filteringPanel.filters.find(
          f => f.name === "category"
        ).options = categories;
      };

      await Promise.all([
        territories(),
        distributors(),
        markets(),
        types(),
        categories()
      ]).then(() => {
        self.filteringPanel.loaded = true;
      });
    }
  },
  watch: {}
};
</script>

<style></style>
